header {
    padding-top: env(safe-area-inset-top);
}

div.header-avatar {
    background-color: var(--accent-color);
}

button.header-button {
    background-color: var(--dark-background-color-elevated);
    margin-left: 12px;
}

@media screen and (prefers-color-scheme: light) {
    button.header-button {
        background-color: var(--light-background-color-elevated);
    }
}

.header-logo {
    border-radius: 8px;
}