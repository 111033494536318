.home-section-content-container.centre {
    margin-right: auto;
    margin-left: auto;
    width: 50%;
}

/* xs */
@media only screen and (max-width: 600px) {
    .home-section-content-container.centre {
        width: var(--app-width-xs);
    }
}

/* sm */
@media only screen and (min-width: 600px) {
    .home-section-content-container.centre {
        width: var(--app-width-sm);
    }
}

/* md */
@media only screen and (min-width: 900px) {
    .home-section-content-container.centre {
        width: var(--app-width-md);
    }
}

/* lg */
@media only screen and (min-width: 1200px) {
    .home-section-content-container.centre {
        width: var(--app-width-lg);
    }
}