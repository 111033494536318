@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto-Regular.ttf");
  font-display: swap;
}

:root {
  --dark-background-color: #1f1f1f;
  --dark-background-color-elevated: #353535;
  --dark-code-background-color: #464646ab;
  --dark-blockquote-border-color: #464646ab;
  --dark-primary-text-color: #f1f1f1;

  --light-background-color: #f1f1f1;
  --light-primary-text-color: #1f1f1f;
  --light-background-color-elevated: #e0e0e0;

  --accent-color: #ff7b00;
  --warning-color: #ee3800;

  --standard-border-radius: 16px;
  --container-border-radius: 12px;

  --app-width-xs: 95%;
  --app-width-sm: 90%;
  --app-width-md: 90%;
  --app-width-lg: 60%;
}

html {
  height: 100vh;
  background-color: var(--dark-background-color);
  color: var(--dark-primary-text-color);
  margin: 0;
  overflow: auto;
}

@media screen and (prefers-color-scheme: light) {
  html {
    background-color: var(--light-background-color);
    color: var(--light-primary-text-color);
  }
}

html::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

html::-webkit-scrollbar-track {
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  background: var(--dark-background-color-elevated);
  border-radius: 10px;
}

body {
  margin: 0;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

a,
a:visited,
a:focus {
  color: var(--dark-primary-text-color)
}

code {
  background-color: var(--dark-code-background-color);
  white-space: break-spaces;
  padding: 0.2em 0.4em;
  border-radius: 6px;
}

pre {
  padding: 16px;
  overflow: auto;
  background-color: var(--dark-code-background-color);
  border-radius: 6px;
  word-wrap: normal;
  font-family: ui-monopace, SFMono-Regular, monospace;
}

blockquote {
  margin: 0;
  padding: 0 1em;
  border-left: 0.25em solid var(--dark-blockquote-border-color);
}

.app-section {
  margin-top: calc(96px + env(safe-area-inset-top));
  padding-bottom: calc(64px + env(safe-area-inset-bottom));
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}

/* xs */
@media only screen and (max-width: 600px) {
  .app-section {
    width: var(--app-width-xs);
  }
}

/* sm */
@media only screen and (min-width: 600px) {
  .app-section {
    width: var(--app-width-sm);
  }
}

/* md */
@media only screen and (min-width: 900px) {
  .app-section {
    width: var(--app-width-md);
  }
}

/* lg */
@media only screen and (min-width: 1200px) {
  .app-section {
    width: var(--app-width-lg);
  }
}

/** --------------------- UTILS --------------------- */
.no-wrap-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrap-content {
  /* white-space: pre-wrap; */
  word-wrap: break-word;
}

/** --------------------- HEADER --------------------- */
.home-page {
  margin-top: 96px;
  padding-bottom: 64px;
}

/** --------------------- DASHBOARD --------------------- */
.search-view-search-input {
  min-width: 300px;
  max-width: 700px;
  text-align: center;
  padding: 16px;
  border-radius: var(--standard-border-radius);
  background-color: var(--dark-background-color);
  color: var(--dark-primary-text-color);
  border: 0;
}

svg.application-list-view-application.aged {
  color: var(--warning-color);
}

.dashboard-page-view {
  margin-top: 18px;
}

.dashboard-view-options {
  margin-top: 16px;
}

/** --------------------- REGISTER --------------------- */
.register-page-message {
  text-align: center;
}

/** --------------------- MARKDOWNGUIDEDIALOG --------------------- */
.markdown-guide {
  margin-bottom: 16px;
}

.markdown-guide-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 8px;
}

.markdown-guide-table td,
.markdown-guide-table th {
  text-align: start;
  border: 1px solid var(--dark-background-color-elevated);
  padding: 8px;
}